import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

import "./about.css";

const PhotographyPage = () => {
  return (
    <Layout>
      <Head title="Photography" />

      <Link to="/covers">
        <li>
          <h3>Covers</h3>
        </li>
      </Link>

      <Link to="/mandalas">
        <li>
          <h3>Mandalas</h3>
        </li>
      </Link>

      <Link to="/sideviewmirrors">
        <li>
          <h3>Sideview Mirrors</h3>
        </li>
      </Link>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1565593996/white%20flowers/IMG_6535.jpg"
        alt="Berkeley white flower"
        style={{ marginTop: 32, marginBottom: 40 }}
      />

      <Link to="/media">
        <h3>Media</h3>
      </Link>
      <Link to="/">
        <h3>Home</h3>
      </Link>
    </Layout>
  );
};

export default PhotographyPage;
